import React, { useEffect, useState, useContext } from "react";
import {
  Dropdown,
  DropdownOption,
  Button,
  Datagrid,
  Spinner,
  Link,
} from "@abb/abb-common-ux-react";
import { RootContext } from "../../contexts/root-context";
import axios from "axios";
import NotificationExample from "../Notification/notification";
import { BASE_URL, OPERATION_AREA } from "../../strings";
import { convertUtcToLocalString, decryptData } from "../../util";
import { getToken } from "../Authentication/get-token";
import "./historical-reports.css";
import jsonSchema from "./list-of-reports.json";

const HistoricalReports = (props) => {
  const id = "BAY-DS-8451";
  const { isnotify, setisnotify } = useContext(RootContext);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [hrData, setHrData] = useState([]);
  const [isSpin, setIsSpin] = useState(true);
  const [isRelaySpin, setIsRelaySpin] = useState(true);
  const [generateReportCaption, setGenerateReportCaption] = useState("Generate Report");
  const [disableReportBtn, setDisableReportBtn] = useState(true);
  const [options, setOptions] = useState([]);
  const [selectedMulti, setSelectedMulti] = useState([]);
  const SUBSTATION_ID = localStorage.getItem("selectedSubstationId");
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    // Dynamically set columns from JSON configuration
    const enabledColumns = jsonSchema.columns.filter((col) => col.enabled);
    setColumns(enabledColumns);
  }, []);

  // Calculate the date 90 days prior to the current date
  const currentDate = new Date();
  const ninetyDaysAgo = new Date();
  ninetyDaysAgo.setDate(currentDate.getDate() - 90);

  // Format the dates to YYYY-MM-DD
  const minDate = ninetyDaysAgo.toISOString().split('T')[0];
  const maxDate = currentDate.toISOString().split('T')[0];

  const commonFlags = {
    clearable: true,
    searchable: true,
    clearOnEscape: true,
    sizeClass: "small",
  };
  const fetchRelays = async () => {
    try {
      let historicalReportUrl =
        BASE_URL + `historical-reports-relays?substationId=${SUBSTATION_ID}`;
      const response = await axios.get(historicalReportUrl, getToken());
      if (response?.data) {
        setOptions(response.data);
      } else {
        console.error("Response data is not available.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const fetchHRData = async () => {
    try {
      const response = await axios.get(
        BASE_URL + `historical-reports-list?substationId=${SUBSTATION_ID}`, getToken()
      );
      const responseData = response?.data;
      // Map and format the data
      const formattedData = responseData?.map((v) => ({
        fields: {
          reportName: v?.name,
          requestedOn: (v?.requestedOn),
          status: v?.status,
          action: jsonSchema.downloadButton.enabled && (
            <Button
              onClick={() => downloadHR(v.name)}
              download={v.name + ".zip"}
              sizeClass={"small"}
              type="discreet-blue"
              text="download"
              shape={"pill"}
              disabled={v?.status !== 'Ready'}
            />
          ),
        },
      }));
      // Update the state with the formatted data
      setHrData(formattedData);

      setIsRelaySpin(false);
      setIsSpin(false);
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const downloadHR = async (name) => {
    const res = await axios.get(
      BASE_URL + `historical-reports-download?fileName=${name}`, getToken()
    );
    const link = res.data.url;
    window.open(link);
  };

  useEffect(() => {
    fetchHRData();
    fetchRelays();
  }, []);

  useEffect(() => {
    validategenerateReport();
    dateValidate();
  }, [selectedMulti, startDate, endDate, generateReportCaption]);

  const validateEndDate = (date) => {
    return date < convertUtcToLocalString(new Date());
  }

  const validateStartDate = (date) => {
    const currentDate = convertUtcToLocalString(new Date());
    const threeMonthsAgo = convertUtcToLocalString(getThreeMonthsAgoDate());

    return !(date > currentDate || date > endDate || date < threeMonthsAgo);
  };

  const getThreeMonthsAgoDate = () => {
    const currentDate = new Date();
    const threeMonthsAgo = new Date(currentDate);
    threeMonthsAgo.setMonth(currentDate.getMonth() - 3);
    return threeMonthsAgo;
  };

  function dateValidate() {
    if (startDate && startDate < convertUtcToLocalString(getThreeMonthsAgoDate())) {
      return "Start date cannot be older than 3 months.";
    } else if (startDate > new Date()) {
      return "Start date cannot be a future date";
    } else if (endDate > new Date()) {
      return "End date cannot be a future date";
    } else if (startDate && endDate && startDate > endDate) {
      return "Start date cannot be after End date";
    } else {
      return "";
    }
  }

  const validategenerateReport = () => {
    if (selectedMulti.length >= 1 && startDate && endDate && validateEndDate(endDate) && validateStartDate(startDate)) {
      setDisableReportBtn(false);
    } else {
      setDisableReportBtn(true);
    }
  };



  function formatDateTime(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}`;
  }

  const handlegenerateReport = async () => {
    if (selectedMulti.length >= 1 && startDate && endDate) {
      setDisableReportBtn(true);
      setisnotify("genrateReport");
      //setgenerateReportCaption("Generating...");
      setTimeout(() => {
        setGenerateReportCaption("Generate Report");
        setisnotify("");
        setEndDate("");
        setStartDate("");
      }, 3000);
      const relays = selectedMulti.map((x) => x.value).join(",");

      try {
        let userId = decryptData(localStorage.getItem('logged_in_user'))?.replace(/"/g, '');
        let substationName = localStorage.getItem("seletectedSubstation");
        const res = await axios.get(
          BASE_URL +
          `historical-reports-genarator?startDate=${startDate}T00:00&endDate=${endDate}T23:59&substationId=${id}` +
          `&relays=${relays}&userId=${userId}&substationName=${substationName}&operationArea=${OPERATION_AREA}&` +
          `requestedOn=${formatDateTime(new Date())}`, getToken()
        );

        if (res.status === 200) {

          if (res.data === 'NO-DR') {
            setisnotify("nodr");
          } else {
            setisnotify("hr");
          }
          setTimeout(() => {
            setGenerateReportCaption("Generate Report");
            setisnotify("");
            setEndDate("");
            setStartDate("");
          }, 3000);
          fetchHRData();
        }
      } catch (error) {

      }
    } else {
      setDisableReportBtn(true);
    }
  };
  return (
    <>
      {isnotify === "genrateReport" ? (
        <NotificationExample notitype={isnotify} />
      ) : (
        ""
      )}
      {isnotify === "hr" ? (
        <NotificationExample notitype={isnotify} />
      ) : (
        ""
      )}
      {isnotify === "nodr" ? (
        <NotificationExample notitype={isnotify} />
      ) : (
        ""
      )}
      <h6 style={{ textAlign: "left", color: "black" }}>
        Generate Historical DR Reports
      </h6>
      <table id="sld-info-table">
        <tr>
          <td className="first-col">
            <b>Substation Name :</b> <br />
            <span>{localStorage.getItem("seletectedSubstation")}</span>
            <br />
          </td>
        </tr>
        <tr>
          <td className="first-col">
            <b>Relay :</b>
            <br />
            <span>
              {isRelaySpin ? (
                <Spinner
                  style={{ margin: "auto" }}
                  color="dark-grey"
                  sizeClass="small"
                />
              ) : (<>
                <Dropdown
                  style={{
                    flex: "1",
                    minWidth: "160px",
                    width: "600px",
                    maxWidth: "600px",
                  }}
                  placeholder="Pick many"
                  monochrome={false}
                  multiselect={true}
                  value={selectedMulti}
                  onChange={(val) => {
                    setSelectedMulti(val);

                  }}
                  initialOpen="multi"
                  {...commonFlags}
                >
                  {options?.map((o, i) => (
                    <DropdownOption key={i} label={o.relay} value={o.relay} />
                  ))}
                </Dropdown>{" "}</>)}
            </span>
          </td>
        </tr>
        <tr>
          <td className="first-col">
            <b>Time Frame :</b>
            <br />
            <span>
              Select the time frame for which you wish to generate DR files.
            </span>
            <br /><br />
            <span
              style={{
                display: "flex",
              }}
            >
              <div>
                <label htmlFor="datemin" style={{ display: "block", marginBottom: "5px", color: "GrayText" }}><b>Start Date</b></label>
                <input style={{flex: "1",
                  minWidth: "160px",
                  width: "300px",
                  maxWidth: "300px", 
                  height: "30px",
                  maxHeight: "30px"}}
                  type="date"
                  id="datemin"
                  name="datemin"
                  min={minDate}
                  max={endDate || maxDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  />
              </div>
              <div style={{paddingLeft: "10px"}}>
                <label htmlFor="datemax" style={{ display: "block", marginBottom: "5px", color: "GrayText" }}><b>End Date</b></label>
                <input style={{flex: "1",
                  minWidth: "160px",
                  width: "300px",
                  maxWidth: "300px", 
                  height: "30px",
                  maxHeight: "30px"}}
                  type="date"
                  id="datemax"
                  name="datemax"
                  min={startDate || minDate}
                  max={maxDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  />
              </div>
            </span>
          </td>
        </tr>
        <tr>
          <td
            className="first-col"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Button
              style={{ padding: "12px", width: "150px" }}
              sizeClass={"small"}
              type={"primary-black"}
              disabled={disableReportBtn}
              shape={"pill"}
              text={generateReportCaption}
              onClick={() => {
                handlegenerateReport();
              }}
            />{" "}
            <p className="date-error">
              {dateValidate()}
            </p>
            <span style={{ paddingTop: "12px" }}>
              Note: Only data from the last three month is available for
              download.{" "}
            </span>
          </td>
        </tr>
      </table>
      <p className="generate-report-header">
        <b>Download Generated Report</b>
        <span className="hr-refresh">
          <Link
            discreet={true}
            iconBefore="abb/synchronize"
            onClick={fetchHRData}
            preventVisited={true}
            underlined={true}>
            {`Refresh`}
          </Link>
        </span>
      </p >
      {
        isSpin ? (
          <Spinner
            style={{ margin: "auto" }}
            color="dark-grey"
            sizeClass="large"
          />
        ) : (
          <Datagrid
            className="historical-report-table"
            data={hrData}
            columns={hrData.length > 0 ? columns : ""}
            rowHeight="small"
            fitToContent={true}
            sortOrder={{
              field: 'requestedOn',
              desc: true
            }}
          />)}
    </>
  );
};

export default HistoricalReports;
