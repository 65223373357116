import React, { useState, useContext, useCallback } from "react";
import "./disturbance-events.css";
import { RootContext } from "../../contexts/root-context";
import axios from "axios";
import {
  BarChart,
  LineChart,
  ScatterChart,
  Bar,
  Line,
  Scatter,
  XAxis,
  YAxis,
  Label,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { ERROR_LIST, BASE_URL } from "../../strings";
import { Spinner, Link } from "@abb/abb-common-ux-react";
import { convertUtcToLocalStringDR } from "../../util";
import { getToken } from "../Authentication/get-token";
import chartSchema from "./chart-schema.json";

function DisturbanceEvents() {

  const { isnotify, startTS, endTS } = useContext(RootContext); //setSpinner is used to show the spinner when the page is loading
  const [isSpin, setIsSpin] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = React.useState([]);
  const backgroundColor = React.useMemo(() => chartSchema?.properties?.backgroundColor, [chartSchema]);

  React.useEffect(() => {
    fetchDistEvents();
  }, [startTS, isnotify]);

  const fetchDistEvents = useCallback(async () => {
    setIsSpin(true);
    try {
      const response = await axios.get(
        BASE_URL +
        `dr-list?startDateTime=${startTS}&endDateTime=${endTS}&substationId=${localStorage.getItem("selectedSubstationId")}`, getToken()
      );
      let jsonArray = response.data.body;
      const sortedData = [...jsonArray].sort((a, b) => {
        // Convert strings to Date objects for comparison
        const dateA = new Date(a.faultTime);
        const dateB = new Date(b.faultTime);
        return dateA - dateB; // Sort in ascending order
      });
      const groupedData = sortedData.reduce((result, item) => {
        const startDate = item.faultTime;
        if (!result[startDate]) {
          result[startDate] = {
            startDate: startDate,
            Acknowledged: 0,
            Unacknowledged: 0,
          };
        }
        if (item.ack) {
          result[startDate].Acknowledged++;
        } else {
          result[startDate].Unacknowledged++;
        }
        return result;
      }, {});
      const groupedArray = Object.values(groupedData);
      const chartData = UniqueXAxisValuesChart(groupedArray)
      setData(chartData);
      setError(false);
      setIsSpin(false);
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status >= 400) {
          setIsSpin(false);
          setError(true);
        }
      } else {
        setError(true);
        setIsSpin(false);
      }
    }
  }, [startTS, isnotify]);

  const UniqueXAxisValuesChart = useCallback((data) => {
    // Merge uv and pv values for the same dates
    const uniqueData = [];
    const dateValuesMap = new Map();
    data.forEach((item) => {
      const { startDate, Acknowledged, Unacknowledged } = item;
      if (dateValuesMap.has(startDate)) {
        const existingValues = dateValuesMap.get(startDate);
        dateValuesMap.set(startDate, {
          Acknowledged: existingValues.Acknowledged + Acknowledged,
          Unacknowledged: existingValues.Unacknowledged + Unacknowledged
        });
      } else {
        dateValuesMap.set(startDate, { Acknowledged, Unacknowledged });
        uniqueData.push(item);
      }
    });
    uniqueData.forEach((item) => {
      const { startDate } = item;
      const { Acknowledged, Unacknowledged } = dateValuesMap.get(startDate);
      item.Acknowledged = Acknowledged;
      item.Unacknowledged = Unacknowledged;
    });
    return uniqueData;
  }, [data]);


  const CustomizedAxisTick = useCallback((props) => {
    const { x, y, payload } = props;
    let startDateTimeXVal = payload.value;
    return (
      <text style={{ fontSize: "8px", fontWeight: "400" }} x={x} y={y} transform={`rotate(-45, ${x + 15}, ${y + 56
        })`} dx={10} dy={16} textAnchor="middle" fill="#666">
        {convertUtcToLocalStringDR(startDateTimeXVal)}
      </text>
    );
  }, []);

  const CustomYAxisTick = useCallback((props) => {
    const { x, y, payload } = props;
    // Convert the decimal value to an integer
    const intValue = Math.round(payload.value);
    return (
      <text x={x} y={y} dy={-10} textAnchor="end" fill="#666">
        {intValue}
      </text>
    );
  }, []);

  const CustomTooltip = useCallback(({ active, payload, label }) => {
    const dateTime = new Date(label);
    const formattedDateTime = dateTime?.toLocaleString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    });
    if (active && payload) {
      return (
        <div className="custom-tooltip">
          <p>{convertUtcToLocalStringDR(formattedDateTime)}</p>
          <p>{`Acknowledged: ${payload[0].value} `}</p>
          <p>{`Unacknowledged: ${payload[1].value} `}</p>
        </div>
      );
    }
    return null;
  }, []);

  return (
    <>
      {isSpin ? (
        <Spinner
          style={{ margin: "auto" }}
          color="dark-grey"
          sizeClass="small"
        ></Spinner>
      ) : (
        <>
          {error ? (
            <span
              style={{ margin: "auto" }}
              color="dark-grey"
            >
              {ERROR_LIST.ERROR_FETCH_DATA}
              <br />
              <Link
                style={{ color: "#1f1f1f" }}
                onClick={() => {
                  fetchDistEvents();
                }}
              >
                Retry
              </Link>
            </span>
          ) : (
            <>
              {data.length > 0 ? (
                <div className="dist-events" style={{ backgroundColor }}>
                {/* The chart can be a Bar chart, Line chart, or Scatter chart. Additionally, the Scatter chart can include a joint line, forming a JointLineScatterChart. */}
                {chartSchema.properties.chartType === "bar" && (
                  <BarChart
                    barCategoryGap={5}
                    barGap={1}
                    width={chartSchema.properties.bar.chartWidth} // keep minimum 400
                    height={chartSchema.properties.bar.chartHeight} // keep minimum 125
                    data={data}
                    padding={{ top: 0, right: 0, left: 40, bottom: 5 }}
                  >
                    <CartesianGrid stroke={false} />
                    <XAxis
                      id="xdatastartDate"
                      dataKey="startDate"
                      angle={-50}
                      dy={10}
                      tick={<CustomizedAxisTick />}
                      padding={{left: chartSchema.properties.bar.xAxisPadding.left, right: chartSchema.properties.bar.xAxisPadding.left}}
                    >
                      <Label value={chartSchema.properties.xAxisLabel} position={{ x: -5, y: 30 }} />
                    </XAxis>
                    <YAxis
                      tickCount={2}
                      dx={0}
                      label={{
                        value: chartSchema.properties.yAxisLabel,
                        angle: -90,
                        position: { x: 10, y: -25 },
                      }}
                      tickFormatter={(value) => Math.round(value)}
                      tick={<CustomYAxisTick />}
                    />
                    <Tooltip content={<CustomTooltip />} />
                    {/* <Tooltip /> */}
                    <Legend
                      verticalAlign="top"
                      formatter={(value, entry, index) => (
                        <span className="text-color-class">{value}</span>
                      )}
                    />
                    <Bar dataKey="Acknowledged" 
                      fill={chartSchema.properties.bar.acknowledged_bar.color} // give hex code or color name for bar
                      barSize={chartSchema.properties.bar.acknowledged_bar.width} // give width of the bar
                    />
                    <Bar dataKey="Unacknowledged"
                      fill={chartSchema.properties.bar.unacknowledged_bar.color}
                      barSize={chartSchema.properties.bar.unacknowledged_bar.width}
                    />
                  </BarChart>
                )}
                {chartSchema.properties.chartType === "line" && (
                  <LineChart
                    width={chartSchema.properties.line.chartWidth} // keep minimum 450
                    height={chartSchema.properties.line.chartHeight}
                    data={data}
                    padding={{ top: 0, right: 0, left: 40, bottom: 5 }}
                  >
                    <CartesianGrid stroke={false} />
                    <XAxis
                      id="xdatastartDate"
                      dataKey="startDate"
                      angle={-50}
                      dy={10}
                      tick={<CustomizedAxisTick />}
                      padding={{left: chartSchema.properties.line.xAxisPadding.left, right: chartSchema.properties.line.xAxisPadding.right}}
                    >
                      <Label value={chartSchema.properties.xAxisLabel} position={{ x: -5, y: 30 }} />
                    </XAxis>
                    <YAxis
                      tickCount={2}
                      dx={0}
                      label={{
                        value: chartSchema.properties.yAxisLabel,
                        angle: -90,
                        position: { x: 10, y: -25 },
                      }}
                      tickFormatter={(value) => Math.round(value)}
                      tick={<CustomYAxisTick />}
                    />
                    <Tooltip content={<CustomTooltip />} />
                    {/* <Tooltip /> */}
                    <Legend
                      verticalAlign="top"
                      formatter={(value, entry, index) => (
                        <span className="text-color-class">{value}</span>
                      )}
                    />
                    <Line dataKey="Acknowledged" 
                      hide={chartSchema.properties.line.acknowledged_line.isHidden}
                      stroke={chartSchema.properties.line.acknowledged_line.color} // give hex code or color name for line
                      strokeWidth={chartSchema.properties.line.acknowledged_line.width} // give width of the line
                    />
                    <Line dataKey="Unacknowledged"
                      hide={chartSchema.properties.line.unacknowledged_line.isHidden}
                      stroke={chartSchema.properties.line.unacknowledged_line.color}
                      strokeWidth={chartSchema.properties.line.unacknowledged_line.width}
                    />
                  </LineChart>
                )}
                {chartSchema.properties.chartType === "scatter" && (
                  <ScatterChart
                    width={chartSchema.properties.scatter.chartWidth}
                    height={chartSchema.properties.scatter.chartHeight}
                    data={data}
                    padding={{ top: 0, right: 0, left: 40, bottom: 0 }}
                  >
                    <CartesianGrid stroke={false} />
                    <XAxis
                      id="xdatastartDate"
                      dataKey="startDate"
                      angle={-50}
                      dy={10}
                      tick={<CustomizedAxisTick />}
                      padding={{left: chartSchema.properties.scatter.xAxisPadding.left, right: chartSchema.properties.scatter.xAxisPadding.right}}
                    >
                      <Label value={chartSchema.properties.xAxisLabel} position={{ x: -5, y: 30 }} />
                    </XAxis>
                    <YAxis
                      tickCount={2}
                      dx={0}
                      label={{
                        value: chartSchema.properties.yAxisLabel,
                        angle: -90,
                        position: { x: 10, y: -25 },
                      }}
                      tickFormatter={(value) => Math.round(value)}
                      tick={<CustomYAxisTick />}
                    />
                    <Tooltip content={<CustomTooltip />} />
                    {/* <Tooltip /> */}
                    <Legend
                      verticalAlign="top"
                      formatter={(value, entry, index) => (
                        <span className="text-color-class">{value}</span>
                      )}
                    />
                    <Scatter dataKey="Acknowledged" 
                      shape={chartSchema.properties.scatter.acknowledged_point.shape}
                      legendType={chartSchema.properties.scatter.acknowledged_point.shape}
                      fill={chartSchema.properties.scatter.acknowledged_point.color} // give hex code or color name for line
                      line={chartSchema.properties.scatter.jointLineScatterChart.acknowledged_line} // whether to join all scatter points of acknowledged type by a line
                      strokeWidth={chartSchema.properties.scatter.jointLineScatterChart.acknowledged_line_width} // incase line is created, give width of the line
                    />
                    <Scatter dataKey="Unacknowledged"
                      shape={chartSchema.properties.scatter.unacknowledged_point.shape}
                      legendType={chartSchema.properties.scatter.unacknowledged_point.shape}
                      fill={chartSchema.properties.scatter.unacknowledged_point.color}
                      line={chartSchema.properties.scatter.jointLineScatterChart.unacknowledged_line}
                      strokeWidth={chartSchema.properties.scatter.jointLineScatterChart.unacknowledged_line_width}
                    />
                  </ScatterChart>
                )}
                  {/* <span className="">{convertUtcToLocalString(startTS.replace('T', ' ').substring(0, 16))}  - {convertUtcToLocalString(endTS.replace('T', ' ').substring(0, 16))}</span> */}
                </div>
              ) : (
                <p className="center-text-error">{ERROR_LIST.NO_EVENTS}</p>
              )}
            </>
          )}
        </>
      )
      }
    </>
  );
}

export default DisturbanceEvents;
