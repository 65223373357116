import React from 'react';
import SubstationFilters from './substation-filters';
import LoadMap from './map';
import jsonSchema from './substation-filters.json';
 
const SubstationExplorer = () => {
  return (
    <div >
      <div className="filter-row row">
        <SubstationFilters substationTitle={jsonSchema.substationExplorerTitle} />
      </div>
      <div className='row'>
        <LoadMap />
      </div>
    </div>
  );
}
export default SubstationExplorer;

