import React from 'react';
import MapContainer from '../../components/Google-Map/MapContainer';
import LeftpaneComponent from '../../components/Leftpane/left-pane';

const LoadMap = () => {
    return (
        <div>
            <LeftpaneComponent />
            <MapContainer />
        </div>
    );
}
export default LoadMap;

