import React, { useState, useContext, useEffect } from "react";
import Map from "./Map";
import { Marker, InfoWindow } from "@react-google-maps/api";
import axios from "axios";
import { Icon } from "@abb/abb-common-ux-react";
import { v4 as uuidv4 } from "uuid";
import medium from "../../assets/md.png";
import newDR from "../../assets/new-dr.gif";
import { RootContext } from "../../contexts/root-context";
import { BASE_URL } from "../../strings";
import { useNavigate } from "react-router-dom";
import { convertUtcToLocalString } from "../../util";
import { getToken } from "../Authentication/get-token";
import "./map.css";
import NotificationExample from "../Notification/notification";
import mapConfig from "./map-config.json";
export default function MapContainer() {
  const {
    startTS,
    endTS,
    primaryFilter,
    opsAreaFilter,
    setSubIds,
    isnotify,
    setisnotify,
  } = useContext(RootContext);
  const [currentSubstationInfo, setCurrentSubstationInfo] = useState([]);
  const [currentSelectedMarkerIndex, setCurrentSelectedMarkerIndex] = useState(0);
  const [map, setMap] = useState(null);
  const [locs, setLocs] = useState([]);
  const [infoWindowOpen, setInfoWindowOpen] = useState(false);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [error, setError] = useState(false);
  const [isSpin, setispin] = useState(true);
  const [rbacManageData, setRbacManageData] = useState([]);
  const navigate = useNavigate();
  const fetchUserList = async () => {
    try {
      const response = await axios.get(
        BASE_URL + `rbac-get`, getToken()
      );
      setRbacManageData(response.data);
      localStorage.setItem("rbacManagerUsersList", JSON.stringify(response.data));
    } catch (error) {
      setError(true);
    }
  };


  useEffect(() => {
    if (map) {
      mapFitBounds();
    }
  }, [map]);
  useEffect(() => {
    fetchUserList();
  }, []);


  useEffect(() => {
    fetchSubstationData();

  }, [infoWindowOpen, primaryFilter, opsAreaFilter]);

  function filterAndExtract(allSubstationTypes, filteredSubTypes) {
    const filteredSubstations = allSubstationTypes
      .filter(sub => filteredSubTypes.includes(sub.type))
    return filteredSubstations;
  }

  function filterAndExtractAreas(allSubstationTypes, filterSubArea) {
    const filteredAreas = allSubstationTypes
      .filter(sub => filterSubArea.includes(sub.operationArea))
    return filteredAreas;
  }

  const fetchSubstationData = async () => {
    // setmapMarkerErrorMsg(MESSAGES.SUBSTATION_AREAS_LOAD_MESSAGE);
    const getCurrentSubstaations = JSON.parse(localStorage.getItem('USER_INFO'));


    if (!infoWindowOpen) {

      if ((getCurrentSubstaations?.operationAreas?.length > 1 && getCurrentSubstaations?.operationAreas[0] !== "" && isnotify !== "unassigned-substation")) {
        setisnotify("loading-substations");
      }
    }
    let opArs = await JSON.parse(localStorage.getItem("USER_INFO"))?.operationAreas;

    try {
      if (startTS !== undefined && endTS !== undefined && opArs !== undefined) {
        let baseURL =
          BASE_URL + `substation-details?startDateTime=${startTS}&endDateTime=${endTS}&operationAreas=${opArs}`;
        const response = await axios.get(baseURL, getToken());
        let originSubstationData = response.data;
        let filteredSubData = [];
        setCurrentSubstationInfo(originSubstationData);
        localStorage.setItem('substationData', JSON.stringify(originSubstationData));
        if (localStorage.getItem('primaryFilter')) {

          const selectedTypes = Object.entries(JSON.parse(localStorage.getItem('primaryFilter')))
            .filter(([key, value]) => value === true)
            .map(([key]) => key);
          if (selectedTypes.length !== 0) {
            originSubstationData = filterAndExtract(originSubstationData, selectedTypes)
            setCurrentSubstationInfo(originSubstationData);

          }
        } else {
          setCurrentSubstationInfo(originSubstationData);
        }
        if (localStorage.getItem('opsarea')) {
          const selectedAreas = Object.entries(JSON.parse(localStorage.getItem('opsarea')))
            .filter(([key, value]) => value === true)
            .map(([key]) => key);
          if (selectedAreas.length !== 0) {
            originSubstationData = filterAndExtractAreas(originSubstationData, selectedAreas)
            setCurrentSubstationInfo(originSubstationData);

          }
        } else {
          setCurrentSubstationInfo(originSubstationData);
        }
        if (filteredSubData.length == 0) {
          const subIds = originSubstationData.map((sub, index) => sub.substationId);
          localStorage.setItem("subIds", JSON.stringify(subIds));
          setSubIds(subIds);
          let substationDataList = originSubstationData?.map((substationDataitem, index) => {
            return JSON.parse(substationDataitem.coordinates.replace(/(\w+):/g, '"$1":'));
          })
          setLocs(substationDataList)

        } else {
          const subIds = filteredSubData.map((sub, index) => sub.substationId);
          localStorage.setItem("subIds", JSON.stringify(subIds));
          setSubIds(subIds);
          let substationDataList = filteredSubData?.map((substationDataitem, index) => {
            // Parse the coordinates string to a JavaScript object
            return JSON.parse(substationDataitem.coordinates.replace(/(\w+):/g, '"$1":'));
          })
          setLocs(substationDataList)

        }

        // setmapMarkerErrorMsg("")
        if (isnotify !== "unassigned-substation") {
          setisnotify("")
        }

        setError(false);
        setispin(false);
      }
    } catch (error) {
      setError(true);
      setispin(false);
    }

  };

  function mapFitBounds() {
    if (!map) return;
    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
  }

  const handleMarkerClick = (marker, substationInfo, currentSelectedMarkerindex) => {
    if (substationInfo) {
      setCurrentSelectedMarkerIndex(currentSelectedMarkerindex)
      setCurrentSubstationInfo(substationInfo)
      setSelectedMarker(marker);
      setInfoWindowOpen(true);
    }

  };

  const gotoDashboard = (subStationName, id) => {
    localStorage.setItem("seletectedSubstation", subStationName)
    localStorage.setItem("selectedSubstationId", id);
    navigate(`/substation-dashboard/${id}`);
  };

  const handleInfoWindowClose = () => {
    setSelectedMarker(null);
    // setInfoWindowOpen(false);
    setInfoWindowOpen(null);
  };
  const substationDisabled = convertUtcToLocalString(currentSubstationInfo[currentSelectedMarkerIndex]?.lastUpdatedOn?.replace('T', ' ').substring(0, 16)) === "NULL" || "";
  return (
    <>
      {isnotify === "loading-substations" ? (
        <NotificationExample notitype={isnotify} />
      ) : (
        ""
      )}
      <Map id="bedas-map-container" setMap={setMap}>
        {locs?.map((loc, index) => (
          <Marker
            icon={currentSubstationInfo[index]?.lastUpdatedOn ? newDR : medium}
            title={currentSubstationInfo[index]?.name}
            key={uuidv4()}
            position={loc}
            onClick={() => handleMarkerClick(loc,
              currentSubstationInfo[index], index)}
            label={{
              text:
                currentSubstationInfo[index]?.disturbanceRecords ?
                  currentSubstationInfo[index]?.disturbanceRecords ==
                    "NULL" ? "0" : currentSubstationInfo[index]?.disturbanceRecords?.toString() : "0",
              color: "white", // Set the label color to white
            }}
          />
        ))}
        {selectedMarker &&
          <InfoWindow
            style={{ bottom: "45px !important" }}
            position={selectedMarker}
            onCloseClick={handleInfoWindowClose}
            visible={infoWindowOpen}
            className="info-box"
          >
            <div className="info-box">
              <div id="overview">
                <p className="info-header">{mapConfig.substationDetails}</p>
              </div>
              <div id="listsub">
                <p className="info-body">
                  <b>{mapConfig.substationName}:</b>
                  <span>{currentSubstationInfo[currentSelectedMarkerIndex]?.name}</span>
                </p>
                <p className="info-body">
                  <b>{mapConfig.operationArea}:</b>
                  <span>{currentSubstationInfo[currentSelectedMarkerIndex]?.operationArea}</span>
                </p>
                <p className="info-body">
                  <b>{mapConfig.type}:</b>
                  <span>{currentSubstationInfo[currentSelectedMarkerIndex]?.type} </span>
                </p>
                <p className="info-body">
                  <b>{mapConfig.disturbanceRecords}:</b>
                  <span>
                    {currentSubstationInfo[currentSelectedMarkerIndex]?.disturbanceRecords ?
                      currentSubstationInfo[currentSelectedMarkerIndex]?.disturbanceRecords ==
                        "NULL" ? "0" : currentSubstationInfo[currentSelectedMarkerIndex]?.disturbanceRecords?.toString() : "0"}
                  </span>
                </p>
                <p className="info-body">
                  <b>{mapConfig.substationCommunication}:</b>
                  <span>
                    {substationDisabled ? "NA" : convertUtcToLocalString(currentSubstationInfo[currentSelectedMarkerIndex]?.lastUpdatedOn?.replace('T', ' ').substring(0, 16))}
                  </span>
                </p>
              </div>
              <div id="overview">{
                !substationDisabled ?
                  <button
                    className="info-button-header"
                    onClick={() => { gotoDashboard(currentSubstationInfo[currentSelectedMarkerIndex]?.name, currentSubstationInfo[currentSelectedMarkerIndex]?.substationId) }}
                    disabled={substationDisabled}
                  >
                    {mapConfig.viewDashboard}
                  </button> :
                  <p className="no-sub"><Icon style={{ paddingRight: "5px" }} name="abb/information-circle-1" sizeClass="small" /><b>{mapConfig.substationNotAvailable}</b></p>
              }
              </div>
            </div>
          </InfoWindow>
        }
      </Map>
    </>
  );
}
