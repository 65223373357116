import React, { useContext } from 'react';
import './card.css';
import { RootContext } from "../../contexts/root-context";

function Card(props) {
  const { totalDistRowCounts, totalNotiRecords } = useContext(RootContext);
  const { widgetName, cardTitleStyle, widgetUpdateTime, component, applystyle, applyScroll, widgetCount } = props;

  let contentClass = "card-content";
  if (applyScroll) {
    contentClass += " card-content-scroll";
  } else if (applystyle) {
    contentClass += " card-content-even-align";
  }

  const getWidgetCount = () => {
    if(widgetName === "Disturbance Records") {
      return `(${totalDistRowCounts})`;
    } else if(widgetName === "Alarm Notifications") {
      return `(${totalNotiRecords})`;
    } else {
      return '';
    }
  };

  return (
    <div className='chart-card'>
      <div className='chart-header'>
        <div className='chart-title'>
          <span style={cardTitleStyle}>{widgetName} {getWidgetCount()}</span>
        </div>
      </div>
      <hr />
      <div className={contentClass} >
        {component}
      </div>
    </div>

  );
}

export default Card;


