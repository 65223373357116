import React, { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownOption,
  Icon,
  Input,
  Button,
  ToggleSwitch,
} from "@abb/abb-common-ux-react";
import { BASE_URL, MESSAGES, SUBSTATION_ROLES } from "../../strings";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { MessageDialog } from "@abb/abb-common-ux-react";
import { getToken } from "../../components/Authentication/get-token";
import "./edit-user.css";

const EditUser = () => {
  const commonFlags = {
    clearable: true,
    searchable: true,
    clearOnEscape: true,
    sizeClass: "small",
  };

  const [] = React.useState();
  const [currentUrl, setcurrentUrl] = useState(window.location.hash);
  const emailRegex = /\/edit-user\/([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/;
  const match = emailRegex.exec(currentUrl);
  const currentUserEmailId = match ? match[1] : null;
  //Switch Inputs
  const [updateUserValue, setUpdateUserValue] = React.useState(false);
  const [accessSubMap, setAccessSubMap] = React.useState(false);
  const [accessSubExp, setAccessSubExp] = React.useState(false);
  const [updateCaesMLFeedback, setUpdateCaesMLFeedback] = React.useState(false);
  const [singleLineValue, setSingleLineValue] = React.useState(false);
  const [acknowledgeValue, setAcknowledgeValue] = React.useState(false);
  const [mobileNotificationValue, setMobileNotificationValue] = React.useState(false);
  const [escalationValue, setEscalationValue] = React.useState(false);
  //Dropdowns
  const [selectedRole, setselectedRole] = React.useState(SUBSTATION_ROLES[0]);
  const [SubstationTableData, setSubstationTableData] = React.useState([]);
  const [opsPlace, setopsPlace] = React.useState([]);
  const [opsSelectedPlace, setopsSelectedPlace] = React.useState([]);
  const [isSetFetchOps, setisSetFetchOps] = useState(false);
  //const [operationAreasList, setoperationAreasList]=useState([]);

  //API Set vars
  const [EditUserDetails, setEditUserDetails] = React.useState();
  const [error, setError] = useState(false);
  const [filteredSubstations, setfilteredSubstations] = useState([]);
  const [isSaveClick, setIsSaveClick] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  //Save button in form
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  //Dropdown
  const [dpEdited, isdpEdited] = useState(false);

  //Navigation
  const navigate = useNavigate();

  //form inputs
  // get from local storage and set to state from rbacManagerUsersList instead of USER_INFO
  // to support other users permissons updation on save
  let localManagerUsersList = JSON.parse(localStorage.getItem('rbacManagerUsersList'))
  const localEditUserInfo = localManagerUsersList.find(user => user.email === currentUserEmailId);
  let getperimissions = localEditUserInfo.permissions;
  const blngetperimissions = Object.keys(getperimissions).reduce((acc, key) => {
    acc[key] = JSON.parse(getperimissions[key]);
    return acc;
  }, {});
  const [options, setOptions] = useState(blngetperimissions);
  useEffect(() => {
    const haveOptionsChanged = Object.keys(options).some(
      key => options[key] !== blngetperimissions[key]
    );
    setIsSaveEnabled(dpEdited || haveOptionsChanged);
  }, [dpEdited, options]);

  useEffect(() => {

  }, [accessSubExp])
  const goToSettings = () => {
    navigate("../settings")

  }
  const goToSettingsFromSave = async () => {
    const response1 = await axios.get(
      BASE_URL + `rbac-get`, getToken()
    );
    localStorage.setItem("rbacManagerUsersList", JSON.stringify(response1.data));
    navigate("../../settings")
  }

  const fetchoperationAreasData = async () => {
    let baseURL = BASE_URL + `substation-details`;
    try {
      const response = await axios.get(baseURL, getToken());

      let duplicateOperationAreas = [];
      response?.data.forEach(element => {
        duplicateOperationAreas.push(element?.operationArea);
      });
      let uniqueoperationAreas = [...new Set(duplicateOperationAreas)];
      setSubstationTableData(response?.data)
      setopsPlace(uniqueoperationAreas);
    } catch (error) {

    }
  }

  function filterAndExtract(substations, filterAreas) {
    const filteredSubstations = substations
      .filter(sub => filterAreas.includes(sub.operationArea))
      .map(sub => ({ substationId: sub.substationId, name: sub.name }));

    return filteredSubstations;
  }
  const getSubstations = (substations, opsArea) => {
    let selectedops = [];
    opsArea?.forEach(element => {
      selectedops.push(element?.value);
    });
    const filteredSubstations = filterAndExtract(substations, selectedops);
    setfilteredSubstations(filteredSubstations)
  }

  useEffect(() => { fetchEditUserData() }, [isSetFetchOps]);

  const setManageUSersData = async () => {
    const response = await axios.get(
      BASE_URL + `rbac-get`, getToken()
    );
    localStorage.setItem("rbacManagerUsersList", JSON.stringify(response.data));
  }

  const fetchEditUserData = async () => {
    try {
      const response = await axios.get(
        BASE_URL + `rbac-get`, getToken()
      );
      localStorage.setItem("rbacManagerUsersList", JSON.stringify(response.data));
      const localManagerUsersList = JSON.parse(localStorage.getItem('rbacManagerUsersList'));
      const localEditUserInfo = localManagerUsersList.find(user => user.email === currentUserEmailId);
      let fetchdetails = localEditUserInfo;
      await fetchoperationAreasData()
      setEditUserDetails(fetchdetails);
      const usersFetchedOpsarea = fetchdetails?.operationAreas;
      if (usersFetchedOpsarea.includes('')) {

      } else {
        const formatedOpsAreas = usersFetchedOpsarea.map(area => ({
          value: area,
          label: area,
          isNew: false
        }));
        setopsSelectedPlace(formatedOpsAreas)
        getSubstations(SubstationTableData, formatedOpsAreas)

      }
      setisSetFetchOps(true)
      setError(false);
      const userPermission = fetchdetails?.permissions;

      setUpdateUserValue(JSON.parse(userPermission?.userManagement));
      setAccessSubExp(JSON.parse(userPermission?.substationExplorer));
      setAccessSubMap(JSON.parse(userPermission?.addSubstationMapping));
      setSingleLineValue(JSON.parse(userPermission?.uploadSingleLineDiagram));
      setUpdateCaesMLFeedback(JSON.parse(userPermission?.caesMLFeedback));
      setAcknowledgeValue(JSON.parse(userPermission?.disturbanceRecordAcknowledgment));
      setMobileNotificationValue(JSON.parse(userPermission?.disturbanceRecordMobileNotification));
      setEscalationValue(JSON.parse(userPermission?.disturbanceRecordEscalationNotification));
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status >= 400 && status < 500) {
          setError(true);
        } else if (status >= 500 && status < 600) {
          setError(true);
        } else {
          setError(true);
        }
      } else {
        setError(true);
      }
    }
  };

  const permissions = {
    userManagement: updateUserValue,
    substationExplorer: accessSubExp,
    addSubstationMapping: accessSubMap,
    uploadSingleLineDiagram: singleLineValue,
    disturbanceRecordAcknowledgment: acknowledgeValue,
    disturbanceRecordMobileNotification: mobileNotificationValue,
    disturbanceRecordEscalationNotification: escalationValue,
    caesMLFeedback: updateCaesMLFeedback,
  };

  //Toggle Button

  const handleOptionChange = (event) => {
    const { name, value } = event?.target;
    setOptions(prevOptions => ({
      ...prevOptions,
      [name]: value
    }));
  };
  // Create the final JSON object
  const SaveOrUpdateUserDetails = () => {
    const updatedUserInfoAsBody = {
      ...EditUserDetails,
      operationAreas: opsSelectedPlace?.map(item => item?.value),
      substations: filteredSubstations?.map(item => item.name),
      permissions: permissions
    };
  
    const existingLocalStorageUserInfo = JSON.parse(localStorage.getItem("USER_INFO"));
    if (existingLocalStorageUserInfo?.email === currentUserEmailId) {
      const updatedLocalStorageUserInfo = {
        ...existingLocalStorageUserInfo,
        operationAreas: opsSelectedPlace?.map(item => item?.value),
        substations: filteredSubstations?.map(item => item.name),
        permissions: permissions
      };
      localStorage.setItem("USER_INFO", JSON.stringify(updatedLocalStorageUserInfo));
    } else {
      const rbacManagerUsersList = JSON.parse(localStorage.getItem("rbacManagerUsersList")) || [];
      const updatedRbacManagerUsersList = rbacManagerUsersList.map(user => 
        user.email === currentUserEmailId ? { 
          ...user, 
          operationAreas: opsSelectedPlace?.map(item => item?.value),
          substations: filteredSubstations?.map(item => item.name),
          permissions: permissions 
        } : user
      );
      localStorage.setItem("rbacManagerUsersList", JSON.stringify(updatedRbacManagerUsersList));
    }
    const saveUrl = BASE_URL + `rbac-put`;
    try {

      const saveResponse = axios.put(saveUrl, updatedUserInfoAsBody, getToken());
      setIsSaveClick(false)
      setIsSaved(true);
      // setManageUSersData();
      fetchEditUserData();
      //fetch entire list and set to localhistory
      
    } catch (error) {
      setIsSaveClick(false)

      if (error.response) {
        const status = error.response.status;
        if (status >= 400 && status < 500) {
          setError(true);
        } else if (status >= 500 && status < 600) {
          setError(true);
        } else {
          setError(true);
        }
      }
    }
  }

  function handleSaveUserInfo() {
    setIsSaveClick(true)
  }

  const userRole = EditUserDetails?.role;


  return (
    <>
      {/* {isSaved ? <div className="overlay-dr"><MessageDialog
        title={MESSAGES.CONFIRMED_USER_TITLE}
        message={MESSAGES.CONFIRMED_USER_BODY}
        buttons="ok"
        icon="abb/settings"
        className="save-confirm-dialog-container"
        onExit={(v) => setIsSaved(false)}
        onOk={() => goToSettingsFromSave()}
      /></div> : ""} */}
      {isSaveClick ?
        <div className="overlay-dr">
          <div className="dialog-containe-edit-user"> <MessageDialog
            title={MESSAGES.CONFIRM_USER_TITLE}
            message={MESSAGES.CONFIRM_USER_BODY}
            className="save-confirm-dialog-container"
            buttons="yesno"
            icon="abb/settings"
            onExit={(v) => setIsSaveClick(false)}
            // onYes={() => SaveOrUpdateUserDetails()}
            onYes={() => {
              SaveOrUpdateUserDetails();
              goToSettingsFromSave(); // Navigate after saving
            }}
            onNo={() => setIsSaveClick(false)}
          /></div> </div> : ""}
      <div className="edit-user-container">
        <p style={{ cursor: "pointer" }} onClick={goToSettings}><span><Icon sizeClass="small" name="abb/left-arrow" /></span>{"  "}<span>Back</span></p>
        <p className="edit-user-header">Edit user: {EditUserDetails?.givenName} {EditUserDetails?.familyName}</p>
        <p className="edit-user-sub-header">Profile Details</p>
        <table id="edit-user-table">
          <tr>
            <td >
              <Input
                label="Name"
                sizeClass="small"
                value={`${EditUserDetails?.givenName} ${EditUserDetails?.familyName}`}
              />
            </td>
            <td >
              <Input
                style={{ paddingLeft: "16px" }}
                label="Email"
                sizeClass="small"
                value={EditUserDetails?.email}
              />
            </td>
          </tr>
          <tr>
            <td className="edit-user-col">
              <Input
                label="Current Location"
                sizeClass="small"
                value={EditUserDetails?.address}
              />
            </td>
            <td className="edit-user-col">
              <Input
                style={{ paddingLeft: "16px" }}
                label="Contact Number"
                sizeClass="small"
                value={EditUserDetails?.phoneNumber}
              />
            </td>
          </tr>
          <tr>
            <td className="edit-user-col">
              <span> User Status</span>
              <br />
              <span>{EditUserDetails?.activeStatus ? "Enabled" : "Disabled"}</span>
            </td>
          </tr>

          <tr>
            <td className="edit-user-col">
              <b>Roles & Operation Area assignment</b>
            </td>
          </tr>
          <tr style={{ "display": "flex", marginTop: "-8px" }}>
            <td className="edit-user-col">
              <Input
                style={{ paddingTop: "3px" }}
                label="Role"
                sizeClass="small"
                value={EditUserDetails?.role}
              />
            </td>
            <td >

              <Dropdown
                name="opsarea"
                className="drop-ops-area"
                style={{ paddingLeft: "16px" }}
                label="Operation Area"
                monochrome={false}
                edited={dpEdited}
                multiselect={true}
                value={opsSelectedPlace}
                onChange={(val) => {
                  isdpEdited(true);

                  setopsSelectedPlace(val);
                  getSubstations(SubstationTableData, val)
                }}
                initialOpen="multi"
                {...commonFlags}
              >
                {opsPlace.map((o) => (
                  <DropdownOption
                    key={o}
                    label={o}
                    value={o}
                  />
                ))}
              </Dropdown>
            </td>
          </tr>
          <tr>
            <td className="edit-user-col">
              <span>Available substations for the Operation areas</span>
            </td>
          </tr>
          <tr>
            <div style={{ "maxWidth": "700px" }}>
              {filteredSubstations.map((substationItem) => {
                return <span className="avail-substn">{substationItem.name}</span>
              })}
            </div>
          </tr>
          <tr>
            <td className="edit-user-col">
              <b>Set Permissions</b>
            </td>
          </tr>
          <tr>
            <td className="edit-user-col">
              <span>Add/Remove/Modify Users</span>
              <ToggleSwitch
                name="modifyuser"
                label={updateUserValue ? "Yes" : "No"}
                value={updateUserValue}
                onChange={(newState) => {
                  setUpdateUserValue(newState);
                  setOptions({ ...options, userManagement: newState })
                }}
                disabled={userRole === 'OperationalManager' ? false : true}
              />
            </td>
          </tr>
          <td className="edit-user-col">
            <span>CAES ML Feedback Access</span>
            <ToggleSwitch
              name="caesml"
              label={updateCaesMLFeedback ? "Yes" : "No"}
              value={updateCaesMLFeedback}
              disabled={true}
            />

          </td>

          <tr>

          </tr>
          <tr>

            <td className="edit-user-col">
              <span>Access Substation Mapping</span>
              <ToggleSwitch
                name="accesmap"
                label={accessSubMap ? "Yes" : "No"}
                value={accessSubMap}

                onChange={(newState) => {
                  setAccessSubMap(newState);
                  setOptions({ ...options, addSubstationMapping: newState })
                }}
                disabled={userRole === 'OperationalManager' ? false : true}
              />
            </td>
          </tr>
          <tr>
            <td className="edit-user-col">
              <span>Access Substation Explorer</span>
              <ToggleSwitch
                name="accesexp"
                label={accessSubExp ? "Yes" : "No"}
                value={accessSubExp}
                onChange={(newState) => {
                  setAccessSubExp(newState);
                  setOptions({ ...options, substationExplorer: newState })

                }}
                disabled={true}


              />
            </td>
          </tr>
          <tr>
            <td className="edit-user-col">
              <span>Update Single Line Diagram</span>
              <ToggleSwitch
                name="updatesld"
                label={singleLineValue ? "Yes" : "No"}
                value={singleLineValue}
                onChange={(newState) => {
                  setSingleLineValue(newState);
                  setOptions({ ...options, uploadSingleLineDiagram: newState })
                }}
                disabled={userRole === 'Technician' ? true : false}
              />
            </td>
          </tr>
          <tr>
            <td className="edit-user-col">
              <span>Acknowledge Disturbance Record</span>
              <ToggleSwitch
                name="ackdist"
                label={acknowledgeValue ? "Yes" : "No"}
                value={acknowledgeValue}
                onChange={(newState) => {
                  setAcknowledgeValue(newState);
                  setOptions({ ...options, disturbanceRecordAcknowledgment: newState })

                }}
              />
            </td>
          </tr>
          {/* removed as this feture is not required as of current release */}
          {/* <tr>
            <td className="edit-user-col">
              <span>
                Receive mobile notification to acknowledge the Disturbance Record
              </span>
              <ToggleSwitch
                label={mobileNotificationValue ? "Yes" : "No"}
                value={mobileNotificationValue}
                onChange={(newState) => {
                  setMobileNotificationValue(newState);
                }}
                disabled={userRole === 'MaintenanceEngineer' ? false : true}
              />
            </td>
          </tr> */}
          <tr>
            <td className="edit-user-col">
              <span>Receive Escalation notification if DR not acknowledged</span>
              <ToggleSwitch
                name="drescalation"
                label={escalationValue ? "Yes" : "No"}
                value={escalationValue}
                onChange={(newState) => {
                  setEscalationValue(newState);
                  setOptions({ ...options, disturbanceRecordEscalationNotification: newState })

                }}
                disabled={userRole === 'OperationalManager' ? false : true}
              />
            </td>
          </tr>
          <tr>
            <td className="edit-user-col">
              <Button
                text="Save"
                disabled={!isSaveEnabled}
                shape={"pill"}
                sizeClass={"small"}
                type={"primary-black"}
                onClick={() => handleSaveUserInfo()}
              />
              <Button
                text="Cancel"
                onClick={goToSettingsFromSave}
                shape={"pill"}
                sizeClass={"small"} />
            </td>
          </tr>
        </table>
      </div>
    </>
  );
};

export default EditUser;
