import React, { useContext, useEffect, useState, useCallback } from "react";
import {
  DropdownOption,
  Dropdown,
  Button,
} from "@abb/abb-common-ux-react";
import axios from "axios";
import getTimestamps from "../../util";
import { RootContext } from "../../contexts/root-context";
import "./substation-explorer.css";
import { BASE_URL } from "../../strings";
import NotificationExample from "../../components/Notification/notification";
import { getToken } from "../../components/Authentication/get-token";
import "./substation-filters.css";
import { useLocation } from "react-router-dom";
import jsonSchema from './substation-filters.json';

function SubstationFilters({ substationTitle }) {
  const [selectedSingle, setSelectedSingle] = useState(localStorage.getItem('selectedTimeFilter'));
  const {
    setdrmetrics,
    setrelayMetrics,
    setselectedTimeTitle,
    startTS,
    setstartTS,
    isnotify,
    setisnotify,
    endTS,
    setendTS,
    subIds,
    mapMarkerErrorMsg,
    setMetricsLoading,
    relay,
    drmetrics
  } = useContext(RootContext);
  const location = useLocation();
  const [_,setError] = useState(false);

  const checkAssignedSubstation = useCallback(() => {
    const getCurrentSubstaations = JSON.parse(localStorage.getItem('USER_INFO'));
    if (getCurrentSubstaations?.operationAreas?.length > 0 && getCurrentSubstaations?.operationAreas[0] === "") {
      setisnotify("no-sub-assign");
    }
  }, [setisnotify]);

  useEffect(() => {
    checkAssignedSubstation();
    fetchRelayMetrics();
    fetchDRMetrics();
  }, [checkAssignedSubstation, startTS, subIds]);

  useEffect(() => {
    console.log("Relay", relay);
    if (relay.length == 0 || drmetrics.length == 0) {
        setMetricsLoading(true);
    } else {
        setMetricsLoading(false);
    }
  }, [relay, drmetrics]);

  const fetchRelayMetrics = useCallback(async () => {
    const currentPage = location.pathname;
    const substationList = currentPage.includes("explorer")
    ? JSON.parse(localStorage.getItem('subIds') || '[]')
    : [localStorage.getItem('selectedSubstationId')];
    if (!substationList.length || substationList === 'null') {
      console.warn('No valid substation IDs found.');
      setrelayMetrics([]);
      return;
    }
    const requestBody = { substationList };
    try {
      const response = await axios.post(
        `${BASE_URL}substation-metrics?startDateTime=${startTS}&endDateTime=${endTS}`,
        requestBody,
        getToken()
      );
      setrelayMetrics(response.data);
      setError(false);
    } catch (error) {
      setError(true);
    }
  }, [startTS, endTS, location.pathname, setrelayMetrics]);

  const fetchDRMetrics = useCallback(async () => {
    const substationList = JSON.parse(localStorage.getItem('subIds') || '[]');
    if (!substationList.length) {
      console.warn('No valid substation IDs found for DR metrics.');
      setdrmetrics([]);
      return;
    }
    const requestBody = { substationList };
    try {
      const response = await axios.post(
        `${BASE_URL}substation-dr-metrics?startDateTime=${startTS}&endDateTime=${endTS}`,
        requestBody,
        getToken()
      );
      setdrmetrics(response.data);
      setError(false);
    } catch (error) {
      setError(true);
    }
  }, [startTS, endTS, setdrmetrics]);

  const getFilterTime = useCallback((filterVal) => {
    const label = String(filterVal[0].label);
    const { startDate, endDate } = getTimestamps(filterVal[0].value);
    // Handle the case when the time filter remains the same
    const filterInput = JSON.parse(localStorage.getItem("filterInput"));
    const title = filterInput ? filterInput.title : { title: "Last 24 hours" };
    if(label === title) return;
    // Clear the metrics when a new time filter is selected
    setdrmetrics([])
    setrelayMetrics([])
    // Show Loading spinner while the metrics are being fetched
    setMetricsLoading(true)
    setSelectedSingle(label);
    setstartTS(startDate);
    setendTS(endDate);
    setselectedTimeTitle(label);
    localStorage.setItem('selectedTimeFilter', label);
    localStorage.setItem("filterInput", JSON.stringify({ title: label, start: startDate, end: endDate }));
  }, [setstartTS, setendTS, setselectedTimeTitle]);

  const setOnRefresh = useCallback(() => {
    const filterInput = JSON.parse(localStorage.getItem("filterInput")) || { title: "Last 24 hours" };
    const timePeriod = jsonSchema.timePeriodDropdown.options.find(sub => sub.title === filterInput.title);
    const modifiedRefreshTimes = getTimestamps(timePeriod.value);
    setstartTS(modifiedRefreshTimes?.startDate);
    setendTS(modifiedRefreshTimes?.endDate);
    localStorage.setItem("filterInput", JSON.stringify({ title: filterInput.title, start: startTS, end: endTS }));
    window.location.reload();
  }, [jsonSchema.timePeriodDropdown.options, setstartTS, setendTS, startTS, endTS]);

  return (
    <>
      <div className="col-5 col-m-12 col-s-12" style={{ padding: 0 }}>
        {(isnotify === "no-sub-assign" || isnotify === "unassigned-substation") && (
          <NotificationExample notitype={isnotify} />
        )}
        <div className="filter-header">
          {substationTitle.enabled && substationTitle.label}
          &nbsp;
          {jsonSchema.lastRefreshLabel.enabled && (
            <span className="last-updated">{jsonSchema.lastRefreshLabel.label}: {new Date().toLocaleString()}</span>
          )}
        </div>
      </div>
      <div className="col-7 col-m-12 col-s-12" style={{ padding: 0 }}>
        <span className="substation-waiting">{mapMarkerErrorMsg}</span>
        <div className="filters-group">
        {jsonSchema.timePeriodDropdown.enabled && (
          <Dropdown
            className="filter-dropdown col-s-12"
            label={jsonSchema.timePeriodDropdown.label}
            placeholder={selectedSingle || "Last 24 Hours"}
            value={selectedSingle}
            onChange={getFilterTime}
          >
            {jsonSchema.timePeriodDropdown.options.map((o) => (
              <DropdownOption key={o.title} label={o.title} value={o.value} />
            ))}
          </Dropdown>
        )}
        {jsonSchema.refreshButton.enabled && (
          <Button
            style={{ margin: "10px" }}
            sizeClass={"small"}
            icon="abb/synchronize"
            type="normal"
            shape={"rounded"}
            text={jsonSchema.refreshButton.label}
            onClick={setOnRefresh}
          />
        )}
        </div>
      </div>
    </>
  );
}
export default React.memo(SubstationFilters);
