import React, { useContext, useMemo, useState } from "react";
import { TabItem, TabControl, WithPopup, Popup, Dialog } from "@abb/abb-common-ux-react";
import Card from "../../components/Card/card";
import SubstationFilters from "../substation-explorer/substation-filters";
import DashboardMetrics from "./metrics";
import DisturbanceEvents from "../../components/Bar-Disturbance-Events/disturbance-events";
import AlarmSummary from "../../components/Donut-Alarm-Summary/alarm-summary";
import DisturbanceMeasurementData from "../../components/Disturbance-Records/disurbance-measurements-datagrid";
import AlarmNotificationsData from "../../components/Alarm-Notifications/alarm-noti-datagrid";
import SLD from "../../components/SLD/sld";
import HistoricalReports from "../../components/Historical-Reports/historical-reports";
import { RootContext } from "../../contexts/root-context";
import chatbot from "../../assets/chat-bot.png";
import { CHATBOT_REDIRECT_LINK } from "../../strings";
import dashboardSchema from './dashboard-schema.json';
import jsonSchema from "./../substation-explorer/substation-filters.json"
function Dashboard() {
  const { relay, drmetrics } = useContext(RootContext);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [activeTab, setActiveTab] = React.useState(0);
  // Helper function to update metrics
  const generateMetrics = (id, data) => {
    return dashboardSchema[id]
      .filter((metric) => metric.enabled)  // Only include enabled metrics
      .map((metric) => ({
        ...metric,
        metricScore: data.metricScore || 0
      }));
  };

  // Memoized metrics to avoid unnecessary recalculations
  const metrics = useMemo(() => ({
    tripMetric: generateMetrics("tripMetric", { metricScore: relay?.trips }),
    distRecordsMetric: generateMetrics("distRecordsMetric", { metricScore: drmetrics?.drs }),
    ackDRMetric: generateMetrics("ackDRMetric", { metricScore: drmetrics?.ackDR }),
    unackDRMetric: generateMetrics("unackDRMetric", { metricScore: drmetrics?.unAckDR }),
    healthyRelayMetrics: generateMetrics("healthyRelayMetrics", { metricScore: relay?.healthyRelays }),
    unHealthyRelayMetrics: generateMetrics("unHealthyRelayMetrics", { metricScore: relay?.unhealthyRelays })
  }), [relay, drmetrics]);
  /*Chatbot*/
  const clearData = () => {
    setIsDialogOpen(false);
  }
  /*chatbot*/
  return (
    <>
      {isDialogOpen &&
        (<Dialog
          className="dialog-container-chatbot"
          showCloseButton={true}
          closeOnEscape={true}
          closeOnLostFocus={true}
          hideBackground={true}
          onClose={() => {
            clearData();
          }}
        >
          <p className="chatbot-head"><img className="chatbot-head-icon" src={chatbot} alt="Chatbot Icon" /><span className="chatbot-title"> CogniE</span></p>
          <iframe className="chat-window" src={CHATBOT_REDIRECT_LINK} ></iframe>
        </Dialog >)
      }
      <div className="row">
        <div className="dashboard-page-content" >
          {/* <UserSession /> */}
          <TabControl
            containerId="tab-container"
            activeTab={activeTab}
            onTabChange={(oldIndex, newIndex) => setActiveTab(newIndex)}
          >
            <TabItem title="DR Monitoring Dashboard">
              <div className="row">
                <SubstationFilters
                  substationTitle={jsonSchema.substationDashBoardTitle}
                />
              </div>
              <div className="row">
                {Object.entries(metrics).map(([key, metricData]) => {
                  // Filter to include only metrics where enabled is true
                  const enabledMetrics = metricData.filter(metric => metric.enabled);

                  // Render DashboardMetrics only if there are enabled metrics
                  return (
                    enabledMetrics.length > 0 && (
                      <div key={key} className="col-sm-6 col-md-4 col-lg-4 col-xl-2" style={{ paddingLeft: "16px" }}>
                        <DashboardMetrics position={3} legends={enabledMetrics} />
                      </div>
                    )
                  );
                })}
              </div>
              <div className="row"  >
                <div className="col-5 widgets-container">
                  <SLD />
                </div>
                <div className="col-4 widgets-container">
                  <Card
                    widgetName="Disturbance Records"
                    widgetUpdateTime="2"
                    component={<DisturbanceMeasurementData />}
                  ></Card>
                  <Card
                    widgetName="Alarm Notifications"
                    widgetUpdateTime="2"
                    component={<AlarmNotificationsData />}
                  ></Card>
                </div>
                <div className="col-3 widgets-container">
                  <Card
                    widgetName={dashboardSchema.disturbanceOccurrence.cardTitle}
                    cardTitleStyle={dashboardSchema.disturbanceOccurrence.cardTitleStyle}
                    widgetUpdateTime="2"
                    component={<DisturbanceEvents />}
                  >
                  </Card>
                  <Card
                    widgetName="Alarm Summary"
                    widgetUpdateTime="2"
                    component={<AlarmSummary />}
                  ></Card>
                </div>
              </div>
            </TabItem>
            <TabItem title="Historical Reports">
              <HistoricalReports />
            </TabItem>
          </TabControl>
          <div>
            <div id="tab-container" />
          </div>
        </div>
        <div id="chatbot-icon">
          <WithPopup >
            <a onClick={() => { setIsDialogOpen(true) }} id="chatbot-icon-link" target="_blank">
              <img src={chatbot} alt="Chatbot Icon" />
            </a>
            <Popup
              trigger={'hover'}
              closeOnLostFocus={true}
              position={'top right'}
              className="c-bot-tooltip" followCursor={false} triggerOnClick={false}>
              <div className="c-bot-tooltip" >
                Hello! I am CogniE,<br />
                How can I Cognify you today?
              </div>
            </Popup>
          </WithPopup>
        </div>
      </div>
    </>
  );
}
export default Dashboard;
