import React, { useContext, useEffect, useMemo, useCallback } from "react";
import { Icon, Spinner } from "@abb/abb-common-ux-react";
import "./dashboard.css";
import { RootContext } from "../../contexts/root-context";

function DashboardMetrics({ legends }) {
    const { startTS, selectedTimeTitle, metricsLoading } = useContext(RootContext);

    useEffect(() => {}, [startTS]);

    // Memoize the styles and icons based on each legend's metricStat
    const metricsContent = useMemo(() => legends.map((legend, index) => {
        const { metricName, metricStat, metricScore } = legend;

        const isStatPositive = metricStat > 0;
        const isStatNegative = metricStat < 0;

        const scoreClass = isStatNegative ? "metric-green metric-score" :
            isStatPositive ? "metric-red metric-score" : "metric-nochange metric-score";

        const arrowClass = isStatNegative ? "metric-green-arrow" :
            isStatPositive ? "metric-red-arrow" : "metric-nochange";

        const arrowIcon = isStatNegative ? "abb/down-arrow" :
            isStatPositive ? "abb/up-arrow" : "abb/minus";

        const statClass = isStatNegative ? "metric-green" :
            isStatPositive ? "metric-red" : "metric-nochange";

        return (
            <div id="dashboard-metrics" key={index}>
                <span className="metric-name">{metricName}</span>
                <div id="metrics-details">
                    <span className={scoreClass}>{metricScore}</span>
                    <span className="metric-stat-dash metric-desc">
                        <span style={{ float: "left" }}>
                            <Icon name={arrowIcon} sizeClass="medium" className={arrowClass} />
                        </span>
                        <span style={{ lineHeight: "24px" }} className={statClass}>
                            {isNaN(Math.abs(metricStat)) ? "" : Math.abs(metricStat)} from {selectedTimeTitle}
                        </span>
                    </span>
                </div>
            </div>
        );
    }), [legends, selectedTimeTitle]);

    if (metricsLoading) {
        return (
            <div id="dashboard-metrics" style={{ backgroundColor: 'white' }}>
                <span className="metric-name">{legends[0].metricName}</span>
                <div className="spinner-container">
                    <Spinner sizeClass="medium" />
                </div>
            </div>
        );
    }

    return <div style={{ backgroundColor: 'white' }}>{metricsContent}</div>;
}

export default DashboardMetrics;
