import React, { useContext, useEffect, useMemo } from "react";
import { GoogleMap, useLoadScript } from "@react-google-maps/api";
import "./map.css";
import { Icon, Spinner } from "@abb/abb-common-ux-react";
import { RootContext } from "../../contexts/root-context";
import { ERROR_LIST } from "../../strings";
import mapConfig from "./map-config.json";
import metricsData from "../../../src/pages/dashboard/dashboard-schema.json";

const Metrics = React.memo(({ legends, metricsLoading, selectedTimeTitle }) => {
    return (
        <div className="bedas-metric-box">
       
            {metricsLoading ? (
                <div className="map-metric-container">
                    <span className="map-metric-name">{legends[0]?.metricName}</span>
                    <div className="spinner-container">
                        <Spinner sizeClass="medium" />
                    </div>
                </div>
            ) : (
                legends.map((legend, index) => (
                    <div className="map-metric-container" key={index}>
                        <span className="map-metric-name">{legend.metricName}</span>
                        <span
                            className={
                                legend.metricStat < 0
                                    ? "metric-green map-metric-score"
                                    : legend.metricStat > 0
                                        ? "metric-red map-metric-score"
                                        : "metric-nochange map-metric-score"
                            }
                        >
                            {legend.metricScore}
                        </span>
                        <span className="metric-bottom">
                            <Icon
                                name={
                                    legend.metricStat < 0
                                        ? "abb/down-arrow"
                                        : legend.metricStat > 0
                                            ? "abb/up-arrow"
                                            : "abb/minus"
                                }
                                sizeClass="medium"
                                className={
                                    legend.metricStat < 0
                                        ? "metric-green-arrow"
                                        : legend.metricStat > 0
                                            ? "metric-red-arrow"
                                            : "metric-nochange"
                                }
                            />
                            <span
                                className={
                                    legend.metricStat < 0
                                        ? "metric-green"
                                        : legend.metricStat > 0
                                            ? "metric-red"
                                            : "metric-nochange"
                                }
                            >
                                {isNaN(Math.abs(legend.metricStat)) ? "" : Math.abs(legend.metricStat)}{" "}
                                from {selectedTimeTitle}
                            </span>
                        </span>
                    </div>
                ))
            )}
        </div>
    );
});

export default function Map(props) {
    const {
        isLeftPaneOpen,
        relay,
        drmetrics,
        selectedTimeTitle,
        startTS,
        endTS,
        mapMarkerErrorMsg,
        metricsLoading,
    } = useContext(RootContext);
    const { setMap, children } = props;
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    });

    useEffect(() => { }, [startTS, relay]);

    const generateMetrics = (id, data) => {
        return metricsData[id]
            .filter((metric) => metric.enabled)
            .map((metric) => ({
                ...metric,
                metricScore: data.metricScore || 0,
            }));
    };

    const metrics = useMemo(
        () => ({
            tripMetric: generateMetrics("tripMetric", { metricScore: relay?.trips }),
            distRecordsMetric: generateMetrics("distRecordsMetric", { metricScore: drmetrics?.drs }),
            unackDRMetric: generateMetrics("unackDRMetric", { metricScore: drmetrics?.unAckDR }),
            unHealthyRelayMetrics: generateMetrics("unHealthyRelayMetrics", { metricScore: relay?.unhealthyRelays }),
        }),
        [relay, drmetrics]
    );

    const renderMap = () => {
        const loadHandler = (map) => {
            setMap(map);
        };

        return (
            <GoogleMap
                id="circle-example"
                mapContainerStyle={{
                    left: "3%",
                    height: "calc(100% - 160px)",
                    width: "calc(98% - 30px)",
                    position: "fixed",
                    zIndex: -1,
                }}
                center={{ ...mapConfig.defaultCenterCoordinates }}
                options={{
                    scrollwheel: true,
                    scaleControl: true,
                    mapTypeId: "roadmap",
                    labels: false,
                    zoomControl: true,
                    streetViewControl: false,
                }}
                onLoad={loadHandler}
            >
                {props.children}

                <div
                id="metrics-container-bottom"
                    className={`legend-container-${isLeftPaneOpen === "full" ? "full" : "thumb"} legends-metrics`}
                  
                >
                    {Object.entries(metrics).map(([key, metricData]) => {
                        const enabledMetrics = metricData.filter((metric) => metric.enabled);
                        return (
                            enabledMetrics.length > 0 && (
                                <div key={key} className="metric-item">
                                    <Metrics
                                        legends={enabledMetrics}
                                        metricsLoading={metricsLoading}
                                        selectedTimeTitle={selectedTimeTitle}
                                    />
                                </div>
                            )
                        );
                    })}
                </div>
            </GoogleMap>
        );
    };

    if (loadError) {
        return <div>{ERROR_LIST.MAP_NOT_LOADED}</div>;
    }

    return isLoaded ? renderMap() : <div>{mapMarkerErrorMsg}</div>;
}
