// Require the moment library


// const moment = require('moment-timezone');
const moment = require('moment');
const momentTimeZone = require('moment-timezone');
export function getDefaultTimeStamp(minutes) {
  minutes = 91440;
  if (!isNaN(minutes)) {

    // Convert the system time to UTC time
    let utcTime = moment.utc();
    const utcFormatTime = utcTime.format('YYYY-MM-DDTHH:mm');

    // Subtract 60 minutes from the UTC time
    const pastTime = utcTime.subtract(minutes, 'minutes');
    // Format the result in YYYY-MM-DDTHH:mm format
    const pastTimeFormat = pastTime.format('YYYY-MM-DDTHH:mm');
    let timestamps = {
      endDate: utcFormatTime,
      startDate: pastTimeFormat,
    };
    return timestamps;
  } else {
    return
  }
}
export default function getTimestamps(minutes) {
  if (!isNaN(minutes)) {
    // Convert the system time to UTC time
    let utcTime = moment.utc();
    const utcFormatTime = utcTime.format('YYYY-MM-DDTHH:mm');

    // Subtract 60 minutes from the UTC time
    const pastTime = utcTime.subtract(minutes, 'minutes');
    // Format the result in YYYY-MM-DDTHH:mm format
    const pastTimeFormat = pastTime.format('YYYY-MM-DDTHH:mm');
    let timestamps = {
      endDate: utcFormatTime,
      startDate: pastTimeFormat,
    };
    return timestamps;
  } else {
    return
  }
}


export function convertUtcToLocalString(utcDateString) {

  if (utcDateString) {
    const utcDate = new Date(utcDateString + ' UTC');
    let localISOString = new Date(utcDate.getTime() - utcDate.getTimezoneOffset() * 60000).toISOString();
    return localISOString.replace('T', ' ').substring(0, 16);
  }
  return "NULL";

}
export function convertUtcToLocalStringDR(drfaulttime) {
  if (drfaulttime) {
    const utcDate = new Date(drfaulttime + " UTC");
    // Define options for formatting
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false // Use 24-hour format
    };

    // Convert UTC time to local time in the specified format
    const localTimeString = utcDate.toLocaleString('en-US', options);

    return localTimeString;
  }
  return "NULL";

}

export function getAlarmSummaryLegendsColor(label) {
  switch (label) {
    case "Earth Fault": {
      return "#CC0815";
      break;
    }
    case "Overcurrent Fault": {
      return "#F2AC66";
      break;
    }
    case "Medium": {
      return "#FFD800";
      break;
    }
    case "Informational": {
      return "#0052FF";
      break;
    }
    case "Ok": {
      return "#51A34B";
      break;
    }
    default: {

      break;
    }
  }
  return;
}
export const encryptData = (data) => {
  try {
    const encryptedData = window.btoa(JSON.stringify(data));
    return encryptedData;
  } catch (error) {
    console.error('Encryption failed:', error);
    return null;
  }
};
export const decryptData = (encryptedData) => {
  try {
    const decryptedData = window.atob(encryptedData);
    return decryptedData;
  } catch (error) {
    console.error('Decryption failed:', error);
    return null;
  }
};
